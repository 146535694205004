import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { RefreshOnlineMessage } from '@app/shared/models';
import { SocketsService } from '@app/shared/services';
import { StatusType } from '@app/shared/types';
import { EMPTY_IMAGE_AVATAR } from '@shared/const/settings';
import { SharedModule } from '@shared/shared.module';
import { takeUntil, ReplaySubject, filter } from 'rxjs';

export enum SizeAvatar {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

@Component({
  selector: 'ln-lawyer-avatar',
  templateUrl: './lawyer-avatar.component.html',
  styleUrls: ['./lawyer-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule]
})
export class LawyerAvatarComponent implements OnInit, OnDestroy {
  _image?: string | undefined | null = null;

  @Input() id?: number = 0;
  @Input() hideStatus?: boolean = false;
  @Input() premium?: boolean = false;
  @Input() size: SizeAvatar = SizeAvatar.Small;
  @Input() style?: string;
  @Input()
  set online(value: boolean | undefined) {
    this.onlineSocket = value;
    this._online = value;
    this.cdr.detectChanges();
  }
  get online() {
    return this.onlineSocket === undefined ? this._online : this.onlineSocket;
  }

  @Input() set image(value: string | undefined | null) {
    if (!!this._image && value !== this._image) {
      this._image = EMPTY_IMAGE_AVATAR;
      setTimeout(() => {
        this._image = value;
        this.cdr.detectChanges();
      });
    } else this._image = value;
  }
  get image() {
    return this._image;
  }

  _online?: boolean;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  onlineSocket?: boolean;
  StatusType = StatusType;
  EMPTY_IMAGE = EMPTY_IMAGE_AVATAR;

  get avatar() {
    return this._image;
  }
  constructor(
    private socketsService: SocketsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.socketsService.refresher
      ?.on<RefreshOnlineMessage>('refresh.online')
      .pipe(
        takeUntil(this.destroyed$),
        filter((data) => data.id === this.id)
      )
      .subscribe((data) => {
        this.onlineSocket = data.online;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onErrorLoadImage(imgElement: any) {
    if (imgElement?.target?.src && imgElement?.target?.src !== EMPTY_IMAGE_AVATAR)
      imgElement.target.src = EMPTY_IMAGE_AVATAR;
  }
}
